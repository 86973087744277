import { type FocusEventHandler } from "react";
import { FormRadio, RadioProps } from "./Radio";

export const RadioGroup = ({
  items,
  disabled,
  value,
  variant,
  onBlur,
  onChange,
}: {
  items: { label: string; value: HTMLInputElement["value"] }[];
  disabled?: RadioProps["disabled"] | undefined;
  value: RadioProps["value"] | undefined;
  variant?: RadioProps["variant"] | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: (value: HTMLInputElement["value"]) => unknown;
}) => {
  return (
    <div>
      {items.map((item) => (
        <FormRadio
          key={item.value}
          label={item.label}
          value={item.value}
          disabled={disabled}
          variant={variant}
          onBlur={onBlur}
          onChange={(e) => onChange?.(e.target.value)}
          checked={value === item.value}
        />
      ))}
    </div>
  );
};
