import styled, { css, CSSProperties } from "styled-components";

import type { HeightProperty, MarginProperties, PaddingProperties, WidthProperty } from "@rimo/ui-old";
import { height, margin, padding, width } from "@rimo/ui-old";

type GapVariant = "4" | "8" | "10" | "12" | "16" | "24" | "32" | "40";

type FlexboxProperties = {
  $alignItems: CSSProperties["alignItems"];
  $justifyContent: CSSProperties["justifyContent"];
  $rowGap: GapVariant;
  $columnGap: GapVariant;
  $gap: GapVariant;
  $flexWrap: CSSProperties["flexWrap"];
  $flexBasis: CSSProperties["flexBasis"];
  $flexGrow: CSSProperties["flexGrow"];
  $flexShrink: CSSProperties["flexShrink"];
  $inline?: boolean;
};

const flexbox = css<Partial<FlexboxProperties>>`
  display: ${({ $inline }) => ($inline ? "inline-flex" : "flex")};
  align-items: ${({ $alignItems }) => $alignItems ?? "initial"};
  justify-content: ${({ $justifyContent }) => $justifyContent ?? "initial"};
  flex-wrap: ${({ $flexWrap }) => $flexWrap ?? "initial"};
  flex-basis: ${({ $flexBasis }) => $flexBasis ?? "initial"};
  flex-grow: ${({ $flexGrow }) => $flexGrow ?? "initial"};
  flex-shrink: ${({ $flexShrink }) => $flexShrink ?? "initial"};
  ${({ $rowGap, $gap }) => ($rowGap || $gap) && `row-gap: ${$rowGap ?? $gap ?? 0}px;`}
  ${({ $columnGap, $gap }) => ($columnGap || $gap) && `column-gap: ${$columnGap ?? $gap ?? 0}px;`}
`;

export const Stack = styled.div<
  Partial<FlexboxProperties & MarginProperties & PaddingProperties & WidthProperty & HeightProperty>
>`
  flex-direction: column;
  ${flexbox}
  ${margin}
  ${padding}
  ${width}
  ${height}
`;

export const HorizStack = styled.div<
  Partial<FlexboxProperties & MarginProperties & PaddingProperties & WidthProperty & HeightProperty>
>`
  flex-direction: row;
  ${flexbox}
  ${margin}
  ${padding}
  ${width}
  ${height}
`;
