import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useThemeMode } from "@rimo/ui-old";

export const VoiceLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  return <Container>{props.children}</Container>;
};

export const VoiceLogo: React.FC = () => {
  const themeMode = useThemeMode();
  const logoSrc = themeMode === "light" ? "rimo_logo.svg" : "logo_white.svg";
  return (
    <Logo>
      <img src={`/assets/${logoSrc}`} alt="logo" />
    </Logo>
  );
};
export const VoiceToolbar: React.FC = () => (
  <Toolbar>
    <VoiceLogo />
  </Toolbar>
);

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: hidden;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/about_voice/waves.svg");
  background-position:
    top ${isMobile ? 90 : 149}px left 0,
    top;
  background-color: ${(p) => p.theme.vars.palette.background.experimental.layeredDefault};
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  padding-top: 24px;
  padding-left: ${isMobile ? 0 : 12}px;
  box-sizing: border-box;
`;

const Logo = styled.span`
  @import url("https://fonts.googleapis.com/css?family=Dosis&display=swap");
  font-family: "Dosis", sans-serif;
`;
