import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import Link from "next/link";
import React, { useMemo, useCallback, useContext } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { CircularProgress } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import { ActionTextBoxRef } from "../../components/layouts/ActionTextbox";
import { Margin } from "../../components/ui";
import { FormPreset } from "@rimo/frontend/pages/About";
import { color } from "@rimo/ui-old";
import { AuthTextbox, EmailRegisterLabel, EmailRegisterError, MobileSubmitButton } from "./EmailRegisterForm";
import { NotificationDispatchContext } from "@rimo/frontend/contexts/NotificationContext";
import { signInWithEmailAndPassword } from "@rimo/frontend/services/firebase/auth/signIn";
import { useInjectUserIntoReactApp } from "@rimo/frontend/hooks/useInjectUserIntoReactApp";

interface FormData {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
}

type EmailLoginProps = FormPreset & {
  hrefResetPassword: string;
  onComplete?: Parameters<typeof signInWithEmailAndPassword>[2];
};

export const EmailLoginForm: React.FC<EmailLoginProps> = (props) => {
  const { customT } = useClientTranslation();
  const dispatch = useContext(NotificationDispatchContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: "onBlur",
    defaultValues: {
      email: props?.email ?? "",
      password: "",
    },
  });

  const [emailRegister, passwordRegister] = useMemo(
    () => [
      register("email", {
        required: "メールアドレスは必須です",
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "不正なメールアドレスです",
        },
      }),
      register("password", {
        required: "パスワードは必須です",
        minLength: {
          value: 6,
          message: "6文字以上入力してください",
        },
      }),
    ],

    [register]
  );

  const injectUser = useInjectUserIntoReactApp();
  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const result = await signInWithEmailAndPassword(data.email, data.password, props.onComplete);
        injectUser(result);
      } catch (e) {
        dispatch({
          type: "ERROR",
          payload: { message: e instanceof Error ? e.message : "不明なエラーが発生しました" },
        });
      }
    },
    [props.onComplete, injectUser, dispatch]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div>
        <AuthTextbox
          errorMessage={errors.email?.message}
          label={customT("メールアドレス")}
          inputType="email"
          registerId="email"
          register={emailRegister}
          required
          placeholder={customT("メールアドレスを入力")}
          autoComplete="email"
        />
      </div>
      <Margin top={12}>
        {isMobile ? (
          <>
            <AuthTextbox
              errorMessage={errors.password?.message}
              label={customT("パスワード")}
              inputType="password"
              registerId="password"
              register={passwordRegister}
              required
              placeholder={customT("パスワードを入力")}
              autoComplete="current-password"
            />

            <MobileSubmitButton />
          </>
        ) : (
          <>
            <EmailRegisterLabel $error={!!errors.password}>{customT("パスワード")}</EmailRegisterLabel>
            <Margin top={6}>
              <ActionTextBoxRef
                error={!!errors.password}
                inputType="password"
                placeholder={customT("パスワードを入力")}
                required
                icon={
                  isSubmitting ? (
                    <CircularProgress size={18} sx={{ color: "text.experimental.onPrimary" }} />
                  ) : (
                    <SubmitIcon />
                  )
                }
                disabled={isSubmitting}
                buttonColor={color.primary}
                buttonType="submit"
                buttonLabel={customT("ログイン")}
                name={passwordRegister.name}
                onChangeInput={passwordRegister.onChange}
                onBlur={passwordRegister.onBlur}
                ref={passwordRegister.ref}
                autoComplete="current-password"
              />

              {errors.password && <EmailRegisterError>{customT(errors.password.message)}</EmailRegisterError>}
            </Margin>
          </>
        )}
      </Margin>
      <Margin top={15}>
        <Link href={props.hrefResetPassword}>
          <PasswordLink>{customT("パスワードを忘れた場合")}</PasswordLink>
        </Link>
      </Margin>
    </form>
  );
};

const SubmitIcon = styled(ArrowForwardRoundedIcon)`
  color: ${(p) => p.theme.vars.palette.text.experimental.onPrimary};
  width: 24px;
  height: 24px;
`;

const PasswordLink = styled.a`
  font-family: Hiragino Sans;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;
